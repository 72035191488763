import Vue from 'vue';
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push //避免路由重复报错
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter); //挂载属性

//创建路由对象并配置路由规则
let router = new VueRouter({
  routes: [
      //一个个对象
      { path: '/', name: '首页',redirect: '/home' },
      {
        path: '/home',
        name: '首页',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/home'),
      },
      {
        path: '/products/:id',
        name: '核心产品',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/products'),
      },
      {
        path: '/pro_zhwl/:id',
        name: '核心产品',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/pro_zhwl'),
      },
      {
        path: '/solutions/:id',
        name: '解决方案',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/solutions'),
      },
      {
        path: '/hardware/:id',
        name: '硬件产品',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/hardware'),
      },
      {
        path: '/case',
        name: '项目案例',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/case'),
      },
      {
        path: '/caseDetail/:id',
        name: '项目案例详情',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/caseDetail'),
      },
      {
        path: '/aboutUs',
        name: '关于我们',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/aboutUs'),
      },
      {
        path: '/contactUs',
        name: '联系我们',
        component: () =>
          import( /* webpackChunkName: "page" */ '@/views/contactUs'),
      },
  ]
});

export default router;